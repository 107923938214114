<template>
  <div>
    <serviceList />
    <serviceAdd />
    <saveBtn
      class="saveBtn"
      :callback="
        function() {
          setSave();
        }
      "
    />
  </div>
</template>
<script>
export default {
  components: {
    serviceList() {
      return import("./list.vue");
    },
    serviceAdd() {
      return import("./add.vue");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    setSave() {
      if (this.$store.state.service.unsaved.length) {
        this.$store.commit("service/update", this.$store.state.service.unsaved);
        this.$router.push({ name: "time" });
      }
    }
  },
  beforeDestroy() {
    this.$store.commit("service/prefetch", this.$store.state.service.services);
  }
};
</script>
<style lang="sass" scoped></style>
